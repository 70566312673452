<template>
    <div class="content">
        <commonTitle></commonTitle>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                 v-loading="loading">
            <el-form-item label="房号" prop="houseNum">
                <el-input v-model="ruleForm.houseNum" placeholder="请输入房号"></el-input>
            </el-form-item>
            <el-form-item label="面积" prop="coveredarea">
                <el-input v-model="ruleForm.coveredarea" placeholder="请输入面积"></el-input>
            </el-form-item>
            <el-form-item label="购房款" prop="housPurchase">
                <el-input v-model="ruleForm.housPurchase" placeholder="请输入购房款"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">保存</el-button>
                <el-button @click="back" size="small">返回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "upHouse",
        components: {
            commonTitle
        },
        data() {
            return {
                ruleForm: {
                    houseId: '',//户室Id
                    houseNum: '',  //楼幢号
                    coveredarea: '',//建筑面积
                    housPurchase: ''//购房款
                },
                rules: {
                    houseNum: [
                        {required: true, message: '请输入户室编号', trigger: 'change'},
                    ], coveredarea: [
                        {required: true, message: '请输入面积', trigger: 'change'},
                    ],
                },
                towerTypeList: [],  //楼幢类型
                dtList: [],        //电梯类型
                payList: [],          //缴费标准
                url: this.$Config.base_server,
                loading: false
            };
        }, mounted() {
            this.common();
        }, methods: {
            common() {
                //获取户室数据
                const houseData = JSON.parse(localStorage.getItem("houseData"));
                this.ruleForm = houseData;
            }
            ,
            selectOne(event, item) {
            }
            , submitForm(formName) {
                const _this = this;
                //立即修改按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        _this.loading = true;
                        $.ajax({
                            url: _this.url + "/api-public/opehouse/update?access_token=" + localStorage.getItem("token"),
                            type: 'post',
                            data: JSON.stringify(_this.ruleForm),
                            contentType: 'application/json',
                            success: function (result) {
                                if (result.resp_code == 0) {
                                    _this.loading = false;
                                    //修改成功
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'success',
                                        duration: 1000,
                                        onClose: function () {
                                            _this.$router.go(-1); // 返回上层路由
                                        }
                                    });
                                } else {
                                    _this.loading = false;
                                    //修改失败
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'warning'
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, back() {
                //返回按钮
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>

    .content {
        padding: 20px 111px;
        background: #fff;
    }

    .el-form {
        width: 600px;
        margin: 0 auto;
    }
</style>